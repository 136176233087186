@import "rsuite/dist/rsuite.css";

@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Sacramento&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&family=Pixelify+Sans:wght@400;500&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,700&family=Roboto&display=swap');

  html,body {
  font-family: 'Poppins';
  background-color:#eaeaea;

  margin: 0;
  padding: 0;
}
.css-1nmdiq5-menu{

  /* height: 100%!important; */
  overflow-y: auto!important;
  position: absolute!important;
  top: 0;
  
}
:root{
  --color-dark: #000;
  --text-color-blue: #10428D;
  --text-color-white: #fff;
  --border-color: #ccc;
  --body-bg-color: #FAFCFF;
  --card-bg-color: #FAFAFA;
  --card-hover-color: #E30F1D;
  --font-size-14: 14px;
  --font-size-big:16px;
  --font-size-15: 15px;
  --background-blue: #034EA2;
  --fontweight-bold:500;
  --fontweight-bolder:600;
  --fontweight-very-bold:700;
  --fontweight-light:400;
  --boxshadow:0 4px 4px 0px rgba(0,0,0,0.3);
  /* --button-background-color:#3D70F4; */
  --deep-blue-background-color:#0369A2;
  --dark-blue-color:#1C396E;
  --btn-approve:#00A846;
  --btn-decline:#D92A35;
}
a{text-decoration: none;}
.card{
  border-radius: 2px;
  border: none;
  color: var(--dark-blue-color);
  font-size: var(--font-size-15);
  font-weight: var(--fontweight-bold);
}
.approval-card-title{
  font-size: var(--font-size-15);
  font-weight: var(--fontweight-bold);
  color: #fff;
  background-color: var(--deep-blue-background-color);
}
.signature{
  font-family: "Sacramento", cursive;
}
:root{
  --text-color-darkblue: #1D3F77;
  --font-weight-bolder:700;
  --color-dark:#163560;
  --light-blue:#115FF5;;
}
.width-80{
  width: 80%;
}
.height-100{
  height: 100vh;
}
.text-grey{
  color: #585858;
}
.otp-input{
  border: 1px solid #000;
  border-radius: 4px;
}

.w-20{
  width: 40%;
}
.font-big{
  font-size: 22px;
  font-weight: var(--font-weight-bolder);
}
.darker-blue-text{
  color: var(--text-color-darkblue);
}
.bolder-text{
  font-weight: var(--font-weight-bolder);
}
.form-title{
  font-size: 70px;
}
.width-75{
  width: 75%;
}
/* .form-control{
  background-color: #fff!important;
  border-color: #727272;
} */
.form-control:focus{
  background-color: #fff!important;
  box-shadow: none;
}

.form-check-input {
  border-color: #000;
}
.input-group-text {
  background-color: #fff!important;
  color: black;
}
.column-1{
  background-color: #fff;
  height: 100vh;
}
.authentication-button{
  width: 100%;
  background-color: var(--light-blue);
  color: #fff;
}
.account-creation-button{
  width: 100%;
  background-color: #fff;
  color:var(--light-blue);
  border: 2px solid var(--light-blue);
}
.header{
  position: fixed;
  top: 0;
  left: 0;
  
  height: 70px; 
  
  z-index: 20;
  background-color: #fff;

}

/* .font-medium{
  font-size: 18px;
  color: #054996;
  font-weight: 700;
} */
.box-shadow{
  box-shadow: 0 1px 4px 0px #ccc; 
}
.rs-btn-primary{
  background-color: #115FF5 !important;
}
.rs-input-group{
  cursor: pointer !important;
}
.width-30{
  height: 60px;
}
.w-30{
  width: 30%;
}
.avatar{
  height: 36px;
}
.sidebar{
  position: fixed;
  /* top: 80px; Adjust top position to match top nav height */
  left: 0;
  /* width: 14%;  */
  height:100vh; /* Adjust height as needed */
  background-color: #fff;
  /* Box shadow on all sides except top */
  /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1), -4px 4px 4px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1); */
  z-index: 20;}

/* Adjust content position to prevent overlap */
.content {
  /* width: 86%; */
  margin-top: 70px; /* Adjust margin top to match top nav height */
  /* margin-left: 14%;  */
}
.sidebar-text{
  color: #034EA2;
  cursor: pointer;
  text-decoration: none;
  
}
.icon{
  fill: #034EA2;
}
.icon.active{
  fill: #fff;
}
.sidebar-text.active{
  fill: #fff;
  background-color: 
  #034EA2;
  color: #fff;
}
.sidebar-text a{
  text-decoration: none;
  color:inherit;
  ;
}
.page-title{
  width: 100%;
  height: 50px;
  background-color: #fff;
  color: #034EA2;
  font-weight: 600;
}
.content-container{
  height: 240px;
  background-color: #fff;
}
.content-container-title{
  height: 47px;
  color: #fff;
}
/* .content-container .form-control,select {
  width: 420px;
} */

.content-container button.grey{
  background-color: #E2E2E2;
  color: #000;
}
.content-container button.blue{
  background-color: #6980E2;
  color: #fff;
}
.light-blue-text{
  color: var(--light-blue);
}
.height-70{
  height: 70%;
}
.table-caption{
  color: #545454;
  font-style: italic;
  
}
.username-input{
  border: 1px solid #c0baba;
  height: 40px!important;
  
}
.item-right{
  max-width: fit-content;
}
.w-preview{
  width: 360px;
}
.success-button-class {
  background-color: #115FF5; 
}
.width-40{
  width: 100%;
}

  .step-menu li {
    box-sizing: border-box; 
    float: left;
    background: #ddd;
    height: 40px;
 
    /* width: 190px; */
    text-align: center;
    padding: 10px 10px 10px 30px;
    position: relative;
    margin-left:10px
    }

    .step-menu li:before {
      content: '';
      position: absolute;
      border: 20px solid transparent;
      border-left-color: #fff;
      top:0px;
      right: -50px;
    }
    .step-menu li:after {
      content: '';
      position: absolute;
      border: 20px solid transparent;
      border-left-color: #ddd;
      top:0px;
      right: -40px;
    }
    .step-menu li:nth-child(1) {
      z-index:600;
    
    }
    .step-menu li:nth-child(2) {
      z-index:500;
    }
    .step-menu li:nth-child(3) {
      z-index:400;
    }
    .step-menu li:nth-child(4) {
      z-index:300;
    }
    .step-menu li:nth-child(5) {
      z-index:200;      
    }
    .step-menu li:nth-child(6) {
      z-index:100;
    }

.step-menu li.current {
  background: rgba(51,122,183, 0.75);
  color: white;
}
.step-menu li.current:after {
  border-left-color: rgba(51,122,183, 0.75);
}
.step-menu li.complete {
  background: rgba(51,122,183, 0.4);
  color: white;
}
.step-menu li.complete:after {
  border-left-color: rgba(51,122,183, 0.4);
}
.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: pulse 1s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.animation-delay-200ms {
  animation-delay: 200ms;
}

.animation-delay-400ms {
  animation-delay: 400ms;
}

.Select {
  /* Assuming 'h-6' and 'w-6' classes set the height and width respectively */
  /* Set display to 'inline-flex' to make the container size fit its content */
  display: inline-flex;
  align-items: center; /* Vertically center the content */
  /* Add any additional styling as needed */
}
.negative-top{
  margin-bottom: -0.3rem;
}
.clip{
  clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 5% 50%, 0 0);
  height: 36px;
  box-shadow: 0 1px 4px 0px #ccc; 
 
}
.divider{

  width:220px;
  color: white;
  border-bottom: 1px solid #000; /* Adjust the colour */
}
/* HTML: <div class="loader"></div> */

.loader {
  --d:22px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  color: #1E40AF;
  
  box-shadow: 
    calc(1*var(--d))      calc(0*var(--d))     0 0,
    calc(0.707*var(--d))  calc(0.707*var(--d)) 0 1px,
    calc(0*var(--d))      calc(1*var(--d))     0 1px,
    calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 2px,
    calc(-1*var(--d))     calc(0*var(--d))     0 3px,
    calc(-0.707*var(--d)) calc(-0.707*var(--d))0 4px,
    calc(0*var(--d))      calc(-1*var(--d))    0 4px;
  animation: l27 1s infinite steps(8);
}
@keyframes l27 {
  100% {transform: rotate(1turn)}
}
@media screen and (max-width: 600px) {
  html,body {
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
  }
  .divider{width: 120px;}
  /* .sidebar{
    width: 100%;
  } */
  .content{
    margin-top: 5rem;
  }
}
.logo{
  height: 3.125rem;
}
.landing-page-main{
  grid-column: 3/ span 12;
  margin: 20px;
}

footer{
  grid-column: 3/ span 12;
  /* grid-row: 3/ span 2; */
}
